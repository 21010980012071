var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import PropTypes from 'prop-types';
var boxStyle = {
    position: 'relative',
    display: 'inline-flex',
    overflow: 'hidden',
};
export var createRipples = function (defaultProps) {
    if (defaultProps === void 0) { defaultProps = {}; }
    var _a;
    return _a = (function (_super) {
            __extends(class_1, _super);
            function class_1(props) {
                var _this = _super.call(this, props) || this;
                _this.timer = 0;
                _this.onClick = function (ev) {
                    var _a = _this.props, during = _a.during, onClick = _a.onClick, color = _a.color;
                    ev.stopPropagation();
                    var pageX = ev.pageX, pageY = ev.pageY, currentTarget = ev.currentTarget;
                    var rect = currentTarget.getBoundingClientRect();
                    var left = pageX - (rect.left + window.scrollX);
                    var top = pageY - (rect.top + window.scrollY);
                    var size = Math.max(rect.width, rect.height);
                    _this.setState(function (state) {
                        return {
                            rippleStyle: __assign({}, state.rippleStyle, { left: left,
                                top: top, opacity: 1, transform: 'translate(-50%, -50%)', transition: 'initial', backgroundColor: color }),
                        };
                    }, function () {
                        _this.timer = setTimeout(function () {
                            _this.setState(function (state) { return ({
                                rippleStyle: __assign({}, state.rippleStyle, { opacity: 0, transform: "scale(" + size / 9 + ")", transition: "all " + during + "ms" }),
                            }); });
                        }, 50);
                    });
                    if (onClick)
                        onClick(ev);
                };
                _this.state = {
                    rippleStyle: {
                        position: 'absolute',
                        borderRadius: '50%',
                        opacity: 0,
                        width: 35,
                        height: 35,
                        transform: 'translate(-50%, -50%)',
                        pointerEvents: 'none',
                    },
                };
                return _this;
            }
            class_1.prototype.componentWillUnmount = function () {
                clearTimeout(this.timer);
            };
            class_1.prototype.render = function () {
                var _a = this.props, children = _a.children, during = _a.during, color = _a.color, onClick = _a.onClick, className = _a.className, props = __rest(_a, ["children", "during", "color", "onClick", "className"]);
                var rippleStyle = this.state.rippleStyle;
                return (React.createElement("div", __assign({}, props, { className: ("react-ripples " + className).trim(), style: boxStyle, onClick: this.onClick }),
                    children,
                    React.createElement("s", { style: rippleStyle })));
            };
            return class_1;
        }(React.PureComponent)),
        _a.displayName = 'Ripples',
        _a.propTypes = {
            during: PropTypes.number,
            color: PropTypes.string,
            onClick: PropTypes.func,
            className: PropTypes.string,
        },
        _a.defaultProps = __assign({ during: 600, color: 'rgba(0, 0, 0, .3)', className: '', onClick: function () { } }, defaultProps),
        _a;
};
